<template>
  <div>
    <v-row class="mx-2">
      <v-col
        md="6"
        cols="12"
        class="my-auto"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            color="primary"
            label="Nome"
            prepend-icon="mdi-card-account-details"
            v-model="userInfo.nome"
            :error-messages="errors"
            :loading="loading"
            :readonly="!podeEditar"
          />
        </validation-provider>
      </v-col>
      <v-col
        md="6"
        cols="12"
        id="col-mpc"
        class="pt-1"
      >
        <v-card-text class="pt-0">
          <label id="mpc">Perfis Associados</label>
          <v-chip-group
            show-arrows
            center-active
          >
            <v-chip
              color="#a2a8aa"
              text-color="white"
              v-for="(perfil, i) in userInfo.perfis"
              :key="i"
            >
              <v-icon left>mdi-label</v-icon>
              {{ perfil }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col cols="6">
        <v-text-field
          color="primary"
          label="Login"
          prepend-icon="mdi-account"
          :value="userInfo.login"
          :loading="loading"
          readonly
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          color="primary"
          label="Tipo"
          prepend-icon="mdi-tag"
          v-model="userInfo.tipo"
          :loading="loading"
          :readonly="!podeEditar"
        />
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col :cols="podeEditar ? '6' : '12'">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            color="primary"
            label="Email"
            prepend-icon="mdi-email"
            v-model="userInfo.email"
            :error-messages="errors"
            :loading="loading"
            :readonly="!podeEditar"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        v-if="podeEditar"
      >
        <v-text-field
          :type="tipoInput"
          color="primary"
          label="Senha"
          prepend-icon="mdi-lock"
          v-model="password"
          :loading="loading"
        >
          <template v-slot:append>
            <v-icon @click="visualizarSenha()"> mdi-eye </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col
        md="4"
        cols="6"
      >
        <v-text-field
          color="primary"
          label="Cargo"
          prepend-icon="mdi-certificate"
          v-model="userInfo.cargo"
          :loading="loading"
          :readonly="!podeEditar"
        />
      </v-col>
      <v-col
        md="4"
        cols="6"
      >
        <v-text-field
          color="primary"
          label="Diretoria"
          prepend-icon="mdi-account-tie"
          v-model="userInfo.diretoria"
          :loading="loading"
          :readonly="!podeEditar"
        />
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <v-text-field
          color="primary"
          label="Area Executiva"
          prepend-icon="mdi-tie"
          v-model="userInfo.area_executiva"
          :loading="loading"
          :readonly="!podeEditar"
        />
      </v-col>
    </v-row>
    <v-col
      class="shrink text-right"
      v-if="podeEditar"
    >
      <v-btn
        color="info"
        outlined
        @click="updateUsuario()"
      >
        Salvar
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import UsuariosService from '@/services/UsuariosService';

export default {
  name: 'PerfilUsuarioDetalhes',
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      database: null,
      requiring: true,
      tipoInput: 'password',
      password: ''
    };
  },
  mounted() {
    this.autenticacaoDatabase();
  },
  computed: {
    podeEditar() {
      return this.database && !this.requiring;
    }
  },
  methods: {
    autenticacaoDatabase() {
      AuthService.getTipoAutenticacao()
        .then((response) => {
          this.database = response === 'database';
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao obter o tipo de autenticação de usuários da aplicação.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.requiring = false));
    },
    visualizarSenha() {
      this.tipoInput === 'password'
        ? (this.tipoInput = 'text')
        : (this.tipoInput = 'password');
    },
    updateUsuario() {
      const user = {
        nome: this.userInfo.nome,
        email: this.userInfo.email,
        tipo: this.userInfo.tipo,
        cargo: this.userInfo.cargo,
        diretoria: this.userInfo.diretoria,
        area_executiva: this.userInfo.area_executiva
      };

      if (this.password !== '') {
        this.$set(user, 'senha', this.password);
      }

      UsuariosService.updateUsuario(user)
        .then(() => {
          this.$toast.success('Dados do usuário atualizados com sucesso.', '', {
            position: 'topRight'
          });
        })
        .catch((error) => {
          this.$toast.error(
            'Ocorreu um erro ao salvar os dados do usuário.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error('Erro:', error);
        });
    }
  }
};
</script>

<style>
#mpc {
  margin-left: 19px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

#col-mpc .v-item-group {
  margin-left: 17px;
}

#col-mpc .v-slide-group--has-affixes {
  margin-left: -35px !important;
  width: 115% !important;
  max-width: 115% !important;
}
</style>
